import React from "react";
import MigrationHero from "../components/MigrationHero";

export default function Migration() {
  return (
    <main className="migration">
      <MigrationHero />
    </main>
  );
}

