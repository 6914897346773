import { React, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../connectors";
import useENSName from "../hooks/useENSName";
import useContract from "../hooks/useContract";
import ERC1155ABI from "../contracts/ERC1155.json";
import ERC721ABI from "../contracts/ERC721.json";
import { UserRejectedRequestError } from "@web3-react/injected-connector";

const OPENSTORE_ADDRESS = "0x495f947276749Ce646f68AC8c248420045cb7b5e";
const GAPES_OLD_ADDRESS = "0x12d2D1beD91c24f878F37E66bd829Ce7197e4d14";
const MIGRATE_ADDRESS = "0x3d22b4f90D2bb22ed6F9E9673527D2c132B2644b";

const etherscanMapping = {
  1: "https://etherscan.io",
};

const openSeaMapping = {
  1: "https://opensea.io",
};

function shortenHex(hex, length = 4) {
  return `${hex.substring(0, length + 2)}…${hex.substring(
    hex.length - length
  )}`;
}

const options = {
  method: "GET",
  headers: {
    Accept: "application/json",
    "X-API-KEY": process.env.REACT_APP_OPENSEA_API_KEY,
  },
};

export default function MigrationHero() {
  const { error, activate, account, setError } = useWeb3React();

  const ENSName = useENSName(account);

  const GENESIS_CONTRACT = useContract(OPENSTORE_ADDRESS, ERC1155ABI);
  const GAPES_CONTRACT = useContract(GAPES_OLD_ADDRESS, ERC721ABI);
  const MIGRATE_CONTRACT = useContract(MIGRATE_ADDRESS, ERC721ABI);

  const [connecting, setConnecting] = useState(false);

  // Genesis Ape States
  const [migrationGenesisTxHash, setMigrationGenesisTxHash] = useState("");
  const [genesisError, setGenesisError] = useState("");
  const [genesisApproval, setGenesisApproval] = useState(false);
  const [genesisApprovalLoading, setGenesisApprovalLoading] = useState(false);
  const [genesisMigrationLoading, setGenesisMigrationLoading] = useState(false);
  const [genesisMigrationSuccess, setGenesisMigrationSuccess] = useState(false);

  // Galactic Ape States
  const [migrationTxHash, setMigrationTxHash] = useState("");
  const [gapeError, setGapeError] = useState("");
  const [gapeApproval, setGapeApproval] = useState(true);
  const [gapeApprovalLoading, setGapeApprovalLoading] = useState(false);
  const [gapeMigrationLoading, setGapeMigrationLoading] = useState(false);
  const [gapeMigrationSuccess, setGapeMigrationSuccess] = useState(false);

  // Galactic Ape Selector States
  const [galacticApeAssets, setGalacticApeAssets] = useState([]);
  const [selectedGalacticApeId, setSelectedGalacticApeId] = useState([]);

  // Genesis Ape Selector States
  const [genesisApeAssets, setGenesisApeAssets] = useState([]);
  const [selectedGenesisApeId, setSelectedGenesisApeId] = useState([]);

  useEffect(() => {
    if (GENESIS_CONTRACT && GAPES_CONTRACT && account) {
      GENESIS_CONTRACT.isApprovedForAll(account, MIGRATE_ADDRESS)
        .then((result) => {
          console.log(result);
          setGenesisApproval(result);
        })
        .catch((e) => console.error(e));
      GAPES_CONTRACT.isApprovedForAll(account, MIGRATE_ADDRESS)
        .then((result) => {
          console.log(result);
          setGapeApproval(result);
        })
        .catch((e) => console.error(e));

      // also populate the the data for what the NFT account owns
      fetch(
        `https://api.opensea.io/api/v1/assets?owner=${account}&collection=galacticapes&order_direction=desc&limit=50&include_orders=false`,
        options
      )
        .then((response) => response.json())
        .then((response) =>
          setGalacticApeAssets(response.assets ? response.assets.reverse() : [])
        )
        .catch((err) => console.error(err));

      // also populate the the data for what the NFT account owns
      fetch(
        `https://api.opensea.io/api/v1/assets?owner=${account}&collection=galacticapesgenesis&order_direction=desc&limit=50&include_orders=false`,
        options
      )
        .then((response) => response.json())
        .then((response) =>
          setGenesisApeAssets(response.assets ? response.assets.reverse() : [])
        )
        .catch((err) => console.error(err));
    }
  }, [GAPES_CONTRACT, GENESIS_CONTRACT, account]);

  const setApproveForAll = async (type) => {
    if (GENESIS_CONTRACT && GAPES_CONTRACT && account) {
      if (type === "genesis") {
        try {
          setGenesisApprovalLoading(true);
          const tx = await GENESIS_CONTRACT.setApprovalForAll(
            MIGRATE_ADDRESS,
            !genesisApproval
          );
          await tx.wait();
          setGenesisApproval(!genesisApproval);
          setGenesisApprovalLoading(false);
        } catch (e) {
          setGenesisApprovalLoading(false);
          console.error(e);
        }
      } else {
        try {
          setGapeApprovalLoading(true);
          const tx = await GAPES_CONTRACT.setApprovalForAll(
            MIGRATE_ADDRESS,
            !gapeApproval
          );
          await tx.wait();
          setGapeApproval(!gapeApproval);
          setGapeApprovalLoading(false);
        } catch (e) {
          setGapeApprovalLoading(false);
          console.error(e);
        }
      }
    }
  };

  const migrateGenesis = async () => {
    if (
      MIGRATE_CONTRACT &&
      GENESIS_CONTRACT &&
      account &&
      selectedGenesisApeId.length
    ) {
      try {
        // if this person is not the owner, give an error
        try {
          for (const genesisApeId of selectedGenesisApeId) {
            const res = await GENESIS_CONTRACT.balanceOf(account, genesisApeId);
            if (parseInt(res.toString()) === 0) {
              setGenesisError(
                `You do not own this Genesis Ape.  Find the correct token id on OpenSea`
              );
              setGenesisMigrationLoading(false);
              return;
            }
          }
        } catch (e) {
          setGenesisError(
            `You do not own this Genesis Ape. Find the correct token id on OpenSea`
          );
          return;
        }
        setGenesisError("");
        setMigrationGenesisTxHash("");
        setGenesisMigrationLoading(true);
        setGenesisMigrationSuccess(false);
        const tx = await MIGRATE_CONTRACT.migrateGenesisGapes(
          selectedGenesisApeId
        );
        setMigrationGenesisTxHash(tx.hash);
        await tx.wait();
        setGenesisMigrationLoading(false);
        setGenesisMigrationSuccess(true);
      } catch (e) {
        setGenesisMigrationLoading(false);
        setGenesisMigrationSuccess(false);
        console.error(e);
      }
    }
  };

  const migrateGape = async () => {
    if (
      MIGRATE_CONTRACT &&
      GAPES_CONTRACT &&
      account &&
      selectedGalacticApeId.length
    ) {
      try {
        // if this person is not the owner, give an error
        try {
          for (const gapeId of selectedGalacticApeId) {
            const res = await GAPES_CONTRACT.ownerOf(parseInt(gapeId));
            if (res.toLowerCase() !== account.toLowerCase()) {
              setGapeError(
                `You do not own this Galactic Ape. Try tokenID n-1 (e.g. Galactic Ape #1 => 0)`
              );
              setGapeMigrationLoading(false);
              return;
            }
          }
        } catch (e) {
          setGapeError(
            `You do not own this Galactic Ape. Try tokenID n-1 (e.g. Galactic Ape #1 => 0)`
          );
          return;
        }
        setGapeError("");
        setMigrationTxHash("");
        setGapeMigrationLoading(true);
        setGapeMigrationSuccess(false);
        const tx = await MIGRATE_CONTRACT.migrateGalacticApes(
          selectedGalacticApeId
        );
        setMigrationTxHash(tx.hash);
        await tx.wait();
        setGapeMigrationLoading(false);
        setGapeMigrationSuccess(true);
      } catch (e) {
        setGapeMigrationLoading(false);
        setGapeMigrationSuccess(false);
        console.error(e);
      }
    }
  };

  return (
    <section className="migration-section">
      <h2 className="migration-header">Prep your crew, migrate your Gapes!</h2>
      <button
        className="connect-wallet"
        disabled={connecting}
        onClick={() => {
          setConnecting(true);
          activate(injected, undefined, true)
            .then(() => setConnecting(false))
            .catch((error) => {
              // ignore the error if it's a user rejected request
              // eslint-disable-next-line no-undef
              if (error instanceof UserRejectedRequestError) {
                setConnecting(false);
              } else {
                setError(error);
              }
            });
        }}
      >
        {account ? ENSName || `${shortenHex(account, 4)}` : "Connect Wallet"}
      </button>
      {error && <p>{error}</p>}
      <div className="container">
        <div className="box-container">
          <h4>Migrate Genesis Ape</h4>
          <p>Migrate your Genesis Ape by pasting your Genesis Ape unique ID.</p>
          <p className="warning-text">
            MAKE SURE TO USE TOKEN ID FROM OPENSEA DETAILS DROPDOWN **NOT
            OVERHEAD TOKEN ID**
          </p>
          <button
            className="approve-button"
            onClick={() => setApproveForAll("genesis")}
            disabled={genesisApprovalLoading}
          >
            {genesisApprovalLoading
              ? genesisApproval
                ? "Revoking Approval…"
                : "Approving Migration…"
              : genesisApproval
              ? "Revoke Approval"
              : "Approve Migration"}
          </button>
          {genesisApeAssets.length !== 0 && (
            <div
              className={`image-box-container${
                genesisApproval ? "" : " disabled"
              }`}
            >
              {genesisApeAssets.map((asset) => (
                <div
                  className={`image-box${
                    selectedGenesisApeId.includes(asset.token_id)
                      ? " selected"
                      : ""
                  }`}
                  onClick={() => {
                    if (selectedGenesisApeId.includes(asset.token_id)) {
                      let index = selectedGenesisApeId.indexOf(asset.token_id);
                      let temp = [...selectedGenesisApeId];
                      if (index !== -1) {
                        temp.splice(index, 1);
                      }
                      setSelectedGenesisApeId(temp.sort());
                    } else {
                      setSelectedGenesisApeId(
                        [...selectedGenesisApeId, asset.token_id].sort(
                          (a, b) => a - b
                        )
                      );
                    }
                  }}
                >
                  <img
                    src={asset.image_url}
                    key={asset.token_id}
                    alt={asset.token_id}
                  />
                  <p>{shortenHex(asset.token_id, 4)}</p>
                </div>
              ))}
            </div>
          )}
          <div className={`box-status${genesisApproval ? "" : " disabled"}`}>
            {selectedGenesisApeId.length !== 0 && (
              <div>
                <h3>Selected Apes:</h3>
                <p>
                  {selectedGenesisApeId.map((id, i) => (
                    <span>{`${shortenHex(id, 4)}${
                      i === selectedGenesisApeId.length - 1 ? "" : ", "
                    }`}</span>
                  ))}
                </p>
              </div>
            )}
            <button
              className="button"
              disabled={
                selectedGenesisApeId.length === 0 || genesisMigrationLoading
              }
              onClick={() => migrateGenesis()}
            >
              {genesisMigrationLoading ? "Migrating..." : "Migrate"}
            </button>
          </div>
          {genesisError && <p className="error">{genesisError}</p>}
          {((genesisMigrationLoading && migrationGenesisTxHash) ||
            genesisMigrationSuccess) && (
            <p className="success-text">
              View your transaction on{" "}
              <a
                href={`${etherscanMapping[1]}/tx/${migrationGenesisTxHash}`}
                target="_blank"
                rel="noreferrer"
              >
                Etherscan
              </a>
            </p>
          )}
          {genesisMigrationSuccess && (
            <p className="success-text">
              View your migrated ape on{" "}
              <a
                href={`${openSeaMapping[1]}/collection/galactic-ape-nft`}
                target="_blank"
                rel="noreferrer"
              >
                OpenSea
              </a>
            </p>
          )}
        </div>

        <div className="box-container">
          <h4>Migrate Galactic Ape</h4>
          <p>Migrate your Galactic Ape by pasting tokenID (0 - 9997).</p>
          <p className="warning-text">
            MAKE SURE TO USE TOKEN ID FROM OPENSEA DETAILS DROPDOWN **NOT
            OVERHEAD TOKEN ID**
          </p>
          <button
            className="approve-button"
            onClick={() => setApproveForAll("gape")}
            disabled={gapeApprovalLoading}
          >
            {gapeApprovalLoading
              ? gapeApproval
                ? "Revoking Approval…"
                : "Approving Migration…"
              : gapeApproval
              ? "Revoke Approval"
              : "Approve Migration"}
          </button>
          {galacticApeAssets.length !== 0 && (
            <div
              className={`image-box-container${
                gapeApproval ? "" : " disabled"
              }`}
            >
              {galacticApeAssets.map((asset) => (
                <div
                  className={`image-box${
                    selectedGalacticApeId.includes(asset.token_id)
                      ? " selected"
                      : ""
                  }`}
                  onClick={() => {
                    if (selectedGalacticApeId.includes(asset.token_id)) {
                      let index = selectedGalacticApeId.indexOf(asset.token_id);
                      let temp = [...selectedGalacticApeId];
                      if (index !== -1) {
                        temp.splice(index, 1);
                      }
                      setSelectedGalacticApeId(temp.sort());
                    } else {
                      setSelectedGalacticApeId(
                        [...selectedGalacticApeId, asset.token_id].sort(
                          (a, b) => a - b
                        )
                      );
                    }
                  }}
                >
                  <img
                    src={asset.image_url}
                    key={asset.token_id}
                    alt={asset.token_id}
                  />
                  <p>{asset.token_id}</p>
                </div>
              ))}
            </div>
          )}
          <div className={`box-status${gapeApproval ? "" : " disabled"}`}>
            {selectedGalacticApeId.length !== 0 && (
              <div>
                <h3>Selected Apes:</h3>
                <p>
                  {selectedGalacticApeId.map((id, i) => (
                    <span>{`${id}${
                      i === selectedGalacticApeId.length - 1 ? "" : ", "
                    }`}</span>
                  ))}
                </p>
              </div>
            )}
            <button
              className="button"
              disabled={
                selectedGalacticApeId.length === 0 || gapeMigrationLoading
              }
              onClick={() => migrateGape()}
            >
              {gapeMigrationLoading ? "Migrating..." : "Migrate"}
            </button>
          </div>
          {gapeError && <p className="error">{gapeError}</p>}
          {((gapeMigrationLoading && migrationTxHash) ||
            gapeMigrationSuccess) && (
            <p className="success-text">
              View your transaction on{" "}
              <a
                href={`${etherscanMapping[1]}/tx/${migrationTxHash}`}
                target="_blank"
                rel="noreferrer"
              >
                Etherscan
              </a>
            </p>
          )}
          {gapeMigrationSuccess && (
            <p className="success-text">
              View your migrated apes on{" "}
              <a
                href={`${openSeaMapping[1]}/collection/galactic-apes-nft`}
                target="_blank"
                rel="noreferrer"
              >
                OpenSea
              </a>
            </p>
          )}
        </div>
      </div>
    </section>
  );
}
